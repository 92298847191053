import React from "react";
import styled from "styled-components";
import { Dialog, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";

import ButtonsPanel from "Components/ButtonsPanel";

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthFalse {
    max-width: 420px;
    padding-top: 0;
    border-radius: 4px;
  }
`;

const StyledContentHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  min-height: 100px;
  margin: 0 auto;
  color: ${({ theme }) => theme.accentTextColor};
  font-size: 18px;
  line-height: 28px;

  a {
    color: ${({ theme }) => theme.basicBlue};

    :hover {
      text-decoration: none;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
  max-height: 70vh;
`;

const Title = styled.div`
  padding-top: 15px;
  color: ${({ theme }) => theme.accentTextColor};
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const ConfirmationModal = ({
  title,
  leftBtnName,
  rightBtnName,
  message,
  leftBtnColor,
  rightBtnColor,
  onLeftBtnClick = () => {},
  onRightBtnClick = () => {},
  disabledRightBtn,
  onBackdropClick = () => {},
  oneButton,
}) => {
  const { t } = useTranslation();

  const handleLeftBtnClick = (e) => {
    onLeftBtnClick(e);
    onBackdropClick();
  };
  const handleRightBtnClick = (e) => {
    onRightBtnClick(e);
    onBackdropClick();
  };

  return (
    <StyledDialog
      open
      onClose={onBackdropClick}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={false}
    >
      <StyledDialogContent dividers={false}>
        {title && <Title>{title}</Title>}
        <StyledContentHolder>{message}</StyledContentHolder>
      </StyledDialogContent>
      <ButtonsPanel
        buttons={[
          {
            styleType: "wideSubmit",
            onClick: handleLeftBtnClick,
            backgroundColor: leftBtnColor || "#d02c75",
            name: leftBtnName || t("CANCEL"),
            hidden: oneButton,
          },
          {
            styleType: "wideSubmit",
            disabled: disabledRightBtn,
            onClick: handleRightBtnClick,
            backgroundColor: rightBtnColor || "#0094F4",
            name: rightBtnName || t("OK"),
          },
        ]}
      />
    </StyledDialog>
  );
};

export default ConfirmationModal;
